import React, { useCallback, useEffect, useMemo, useState } from "react";
import cookie from "react-cookies";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { toast } from "react-toastify";

import * as UserDucks from "ducks/accounts/user";
import * as PaymentDucks from "ducks/vendors/payment";

import CollapseIconAtom from "atoms/VideoCollapseIcon";
import FooterSection from "sections/common/Footer";
import SidenavSection from "sections/common/Sidenav";
import SubHeaderSection from "sections/common/SubHeader";
import CollapsableInfoSection from "sections/common/CollapsableInfo";
import LayerAtom from "atoms/NewLayerModal";
import StorageGateway from "lib/storage-gateway";

import TrialPeriodNotification from "components/common/TrialPeriodNotification/trialPeriodNotification";
import Menu from "./ham-menu.png";

/**
 * TwoColumnLayout Layout
 *
 * Sections:
 *    - {@link SidenavSection}
 *    - {@link SubHeaderSection}
 *    - Content from children
 *    - {@link FooterSection}
 *
 * @example
 *  __________________________________
 * |            |                     |
 * |            |      SubHeader      |
 * |            |_____________________|
 * |            |                     |
 * |            |                     |
 * |  Sidenav   |       Content       |
 * |            |                     |
 * |            |_____________________|
 * |            |                     |
 * |            |       Footer        |
 * |____________|_____________________|
 *
 */

let company_id = "";
let nre_token = "";
let nre_company_id = "";
let userID = "";
const TwoColumnLayout = (props) => {
  const {
    infoList,
    toggleInfo,
    isInfoOpen,
    userCompany,
    isBusinessAdmin,
    customerDetail,
    userProfile,
    userExtraData,
    newDashboard,
  } = props;
  const [sidemenuCollapsed, setSidemenuCollapsed] = useState(false);
  const [toggleMailLayer, setToggleMailLayer] = useState(false);
  const tutorialCompleted = userProfile?.company?.tutorial_completed;
  const isEmployeeBool = userExtraData?.company_permissions?.is_employee;
  const isCompanyOwner = userExtraData?.company_permissions?.company_owner;

  const eventListnerFunction = useCallback((event) => {
    try {
      const data =
        event.data && typeof event.data !== "object" && JSON.parse(event.data);
      const message = data?.message;
      if (message === "CLOSE_NOTIFICATION_LAYER" && toggleMailLayer) {
        setToggleMailLayer(false);
      }
    } catch (e) {}
  }, []);

  useEffect(() => {
    if (cookie.load("sidemenuCollapsed") === "false") {
      setSidemenuCollapsed(false);
      props.getNavStatus && props.getNavStatus(true);
    } else if (cookie.load("sidemenuCollapsed") === "true") {
      setSidemenuCollapsed(true);
      props.getNavStatus && props.getNavStatus(false);
    }
    company_id = StorageGateway.get("company_id");
    nre_token = StorageGateway.get("graniteToken");
    nre_company_id = StorageGateway.get("nre_company_id");
    userID = StorageGateway.get("userId");

    window.addEventListener("message", eventListnerFunction, false);
    return () => {
      window.removeEventListener("message", eventListnerFunction);
    };
  }, []);

  const toggleCollapse = () => {
    setSidemenuCollapsed(!sidemenuCollapsed);
    props.getNavStatus && props.getNavStatus(this.state.sidemenuCollapsed);
  };

  const showToast = () => {
    return toast.error(
      "Please finish the tutorial in order to add the card details. "
    );
  };

  const paddingValue = useMemo(() => {
    return sidemenuCollapsed ? "55px" : "245px";
  }, [sidemenuCollapsed]);

  useEffect(() => {
    cookie.save("sidemenuCollapsed", sidemenuCollapsed ? "true" : "false", {
      path: "/",
    });
  }, [sidemenuCollapsed]);

  const showFlap =
    !isEmployeeBool &&
    isCompanyOwner &&
    Object.keys(customerDetail).length &&
    (customerDetail.commercial_status === "Trial Period" ||
      moment(customerDetail.trial_end) >= moment()) &&
    !isBusinessAdmin &&
    customerDetail.commercial_status !== "No Billing";
  const contanierHeight = showFlap ? "calc(100vh - 50px)" : "100vh";
  if (showFlap) {
    cookie.save("isBannerFlag", "true", { path: "/" });
  }
  return (
    <div className={showFlap ? "dashboard-mainbody" : null}>
      {showFlap ? (
        <div>
          <div className="pending">
            <TrialPeriodNotification
              customerDetail={customerDetail}
              tutorialDetail={tutorialCompleted}
              showToast={showToast}
              userExtraData={userExtraData}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className={showFlap ? "less-height" : "header"}>
        <div
          className={
            sidemenuCollapsed
              ? isBusinessAdmin
                ? "business-collapsed-side-nav side-nav-common"
                : "collapsed-side-nav side-nav-common"
              : isBusinessAdmin
              ? "business-side-nav side-nav-common"
              : "side-nav side-nav-common"
          }
          style={{
            width: paddingValue,
            zIndex: "11",
            minHeight: contanierHeight,
          }}
        >
          <div className={!props?.isFinished ? null : "nav-wrapper-onboard"} />
          <div
            className="collapse-menu-icon"
            role="button"
            tabIndex="0"
            onClick={toggleCollapse}
          >
            <img src={Menu} height="14" width="16" alt="" />
          </div>
          {userExtraData && userExtraData?.company_permissions && (
            <SidenavSection
              isFinished={props?.isFinished}
              sidemenuCollapsed={sidemenuCollapsed}
              showFlap={showFlap}
            />
          )}
        </div>
        <div
          className="content container-padding"
          style={
            props?.hideContent
              ? {
                  marginLeft: paddingValue,
                  transition: "margin-left .5s",
                  minHeight: contanierHeight,
                  display: "none",
                }
              : {
                  marginLeft: paddingValue,
                  transition: "margin-left .5s",
                  minHeight: contanierHeight,
                }
          }
        >
          {infoList?.results?.length ? (
            <div>
              <CollapsableInfoSection
                infoList={infoList?.results}
                toggleInfo={toggleInfo}
                isInfoOpen={isInfoOpen}
              />
              <div className="collapse-text pr-3">
                <CollapseIconAtom
                  active={isInfoOpen}
                  className="cursor-pointer"
                  onClick={() => toggleInfo()}
                  id="collapseIcon"
                  height="1px"
                />
              </div>
            </div>
          ) : null}
          {!props?.isdashboard && (
            <SubHeaderSection
              notification={props?.notification}
              toggle={props?.toggle}
              navInfo={props?.navInfo}
              jobPage={props?.jobPage}
              editJob={props?.editJob}
              fetchData={props?.fetchData}
              isLoading={props?.isLoading}
              jobDetail={props?.jobDetail}
              createJob={props?.createJob}
              goTo={props?.goTo}
              createSurvey={props?.createSurvey}
              addPolicy={props?.addPolicy}
              ptoBulkUpload={props.ptoBulkUpload}
              setToggleMailLayer={setToggleMailLayer}
            />
          )}
          {window.location.pathname === "/dashboard"
            ? React.Children.map(props.children, (child) =>
                React.isValidElement(child)
                  ? React.cloneElement(child, { isInfoOpen: isInfoOpen })
                  : child
              )
            : props.children}
          <FooterSection newDashboard={newDashboard} />
        </div>
      </div>
      {toggleMailLayer ? (
        <LayerAtom
          active={true}
          heading={"Notification Settings"}
          path={`${
            process.env.REACT_APP_WMAPPER_BASE_URL
          }/notification-settings?access_token=${nre_token}&user_id=${userID}&companyId=${company_id ||
            nre_company_id}`}
          setToggle={() => setToggleMailLayer(false)}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userCompany: UserDucks.userCompany(state),
    isBusinessAdmin: UserDucks.isBusinessAdmin(state),
    customerDetail: PaymentDucks.customerDetail(state),
    userProfile: UserDucks.profile(state),
    userExtraData: UserDucks.userExtraData(state),
  };
};

TwoColumnLayout.propTypes = {
  children: PropTypes.node.isRequired,
  navInfo: PropTypes.object,
};

export default React.memo(connect(mapStateToProps)(TwoColumnLayout));
