import React from "react";
import styles from "./styles.module.scss";

const FooterSection = () => {
  return (
    <>
      <div className={styles.footer} style={{ background: "#efefef" }}>
        <div>
          &copy; <span>{new Date().getFullYear()}</span> VendorVer. All rights
          reserved. VendorVer and Worksana are trademarks of VendorVer Inc.
          <span style={{ margin: "10px" }}>
            <a
              href="https://www.worksana.com/terms-and-conditions"
              rel="noopener noreferrer"
              target="_blank"
            >
              Terms of Service
            </a>
          </span>
          <span>
            <a
              href="https://www.worksana.com/privacy-policy"
              rel="noopener noreferrer"
              target="_blank"
            >
              Privacy Policy
            </a>
          </span>
        </div>
        <small className={styles.footerRight}>
          POWERED BY{" "}
          <a
            href="http://www.thoughts2binary.com"
            rel="noopener noreferrer"
            target="_blank"
          >
            T2B GRANITE
          </a>
        </small>
      </div>
    </>
  );
};

export default FooterSection;
